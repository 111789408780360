import React from 'react'
import Link from 'gatsby-link'
import Layout from "../components/layout"

const PrivacyPolicy = ({}) => (
  <Layout>
    <section class="pt3 pb2-ns pb1 mt3 tc w-70-l w-80 center">
        <div class="mb1 pa2 pt3 pb5 w-100 tl">
            <a name="top">
                <h1 class="tracked lh-title nowrap f3-ns f4 pb0 mb0">Frendli Privacy Policy</h1>
            </a>
            <h2 class="text lh-copy f5">Effective date: [05/29/2018]</h2>
            <p class="text lh-copy f5">This Privacy Policy tells you how we collect, use and share information we collect
                on our Frendli mobile application and related website frendli.com (“App”) effective as of the date posted
                above. By using the App and submitting information you agree that we may use and disclose your information
                according to the terms of this Policy. </p>
            <p class="text lh-copy f5"> </p>
            <ul class="text lh-copy f5">
                <li><a href="#1">Information collected and how it is used</a></li>
                <li><a href="#2">Children’s Privacy</a></li>
                <li><a href="#3">Accessing your information and your choices</a></li>
                <li><a href="#4">Sharing your information.</a></li>
                <li><a href="#5">Your California Privacy Rights</a></li>
                <li><a href="#6">Security</a></li>
                <li><a href="#7">Data Processing and Cross-Border Data Transfers</a></li>
                <li><a href="#8">Links to Other Sites</a></li>
                <li><a href="#9">Changes to the Privacy Policy</a></li>
                <li><a href="#10">Contacting Us</a></li>
            </ul>
            <p></p> <a name="1"></a>
            <h3 class="text lh-copy f5"><a name="1">Information collected and how it is used. </a><a href="#top">[top of
                    page]</a></h3>
            <p class="text lh-copy f5"> We automatically collect information when you download and use the App including
                your device’s IP address, and statistics about how you use the App. We collect information that you submit
                when using the App including your name, email or postal address, social media accounts, date of birth,
                gender, or payment card information (which is collected by a third party service provider) if you make a
                purchase (name, billing and shipping address, card information, email and telephone number). We will also
                collect any photo you submit to be used as a profile picture. You may also provide additional information
                such as relationship status, sexual orientation, interests and other personal preferences, but these are
                optional. We may also receive information about you, your digital identity, or your contacts from your
                social media account depending on how you have set your preferences on such account. </p>
            <p class="text lh-copy f5"> We use the collected information to match users together for friendship and to
                match users to special offers, deals, or experiences from merchants or other organizations. We also use the
                information for our general commercial purposes such as to improve our App, grow our business and to offer
                our own, our affiliates’ or third-party products or services that we think you may find of interest. We use
                your contact information to respond to your inquiries or to provide information on products or services to
                you. We use cookies and similar technology to collect aggregate (non-personal) information about App usage
                by all of our visitors and to help us remember you and your preferences when you revisit the App. These
                cookies may stay on your browser into the future until they expire or you delete them. We also use
                technology to remember your purchase selections in your shopping cart. These cookies usually are erased
                when you close your browser window. Further general information about cookies and how they work is
                available at www.allaboutcookies.org . We may allow selected third parties to place cookies through the App
                to provide us with better insights into the use of the App or user demographics or to provide relevant
                advertising to you. These third parties may collect information about a consumer’s online activities over
                time and across different websites when he or she uses our website. We may also permit third party service
                providers to place cookies through our App to perform analytic or marketing functions where you are
                notified of them and you have consented to the usage. We do not control the use of such third party cookies
                or the resulting information and we are not responsible for any actions or policies of such third parties.
                We do not use technology that recognizes a “do-not-track” signal from your web browser. </p> <a name="2"></a>
            <h3 class="text lh-copy f5"><a name="2">Children’s Privacy </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> This App is not designed nor intended to be attractive to use by children or anyone
                under the age of 18. We do not knowingly collect information from children or anyone under the age of 18.
                If you are under 18 please do not submit any information to us. </p> <a name="3"></a>
            <h3 class="text lh-copy f5"><a name="3">Accessing your information and your choices. </a><a href="#top">[top of
                    page]</a></h3>
            <p class="text lh-copy f5"> You can update or amend your information at any time through the mobile application
                or delete your information by emailing <a href="mailto:help@frendli.com">help@frendli.com</a>. You can
                choose not to receive emails from us by “unsubscribing” using the instructions in any email you receive
                from us. This will not stop us from sending emails about your account or your transactions with us. You can
                choose to be contacted by email or through the mobile application. You can choose to delete or block
                cookies by setting your browser to either reject all cookies or to allow cookies only from selected sites.
                If you block cookies performance of the App may be impaired and certain features may not function at all.
            </p> <a name="4"></a>
            <h3 class="text lh-copy f5"><a name="4">Sharing your information. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> Please be aware that information that you provide for your profile may be available
                to other users of the App, so use care when submitting your profile information. </p>
            <p class="text lh-copy f5"> We may reveal information about you to unaffiliated third parties: (1) if you
                request or authorize it; (2) if the information is provided to help complete a transaction for you; (3) if
                the information is provided to comply with the law, applicable regulations, governmental and
                quasi-governmental requests, court orders or subpoenas, to enforce our Terms of Use or other agreements, or
                to protect our rights, property or safety or the rights, property or safety of our users or others (e.g.,
                to a consumer reporting agency for fraud protection etc.); (4) if the disclosure is done as part of a
                purchase, transfer or sale of services or assets (e.g., in the event that substantially all of our assets
                are acquired by another party, customer information may be one of the transferred assets); (5) if the
                information is provided to our agents, outside vendors or service providers to perform functions on our
                behalf (e.g., analyzing data, providing marketing assistance, providing customer service, processing
                orders, etc.); or (6) as otherwise described in this Privacy Policy. </p>
            <p class="text lh-copy f5"> We may share your information with our affiliated companies but they are not
                permitted to use it for marketing purposes. We do not share your information with third parties for their
                marketing purposes. We may disclose your non-private, aggregated, or otherwise non-Personal Information,
                such as usage statistics of our services, to our affiliates and third parties. </p> <a name="5"></a>
            <h3 class="text lh-copy f5"><a name="5">Your California Privacy Rights. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> California residents are permitted by California law to request information about
                the manner in which we share certain categories of information with others for their marketing purposes. We
                do not share your personal information with third parties for direct marketing purposes. </p> <a name="6"></a>
            <h3 class="text lh-copy f5"><a name="6">Security. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5">We use reasonable technical, administrative and physical measures to protect
                information contained in our system against misuse, loss or alteration. We may use standard SSL technology
                to protect information being transferred to our App, but no method of electronic transmission or storage is
                100% secure. If we receive instructions using your log-in information we will consider that you have
                authorized the instructions. </p> <a name="7"></a>
            <h3 class="text lh-copy f5"><a name="7">Data Processing and Cross-Border Data Transfers </a><a href="#top">[top
                    of page]</a></h3>
            <p class="text lh-copy f5"> Our App is maintained on servers located in the United States, and personal
                information submitted is stored on our servicers in the United States. If you are using our App from
                outside the United States, please be advised that your information is transferred to our U.S. servers.
                Disclosing your personal information to us pursuant to this Privacy Policy is at your own risk. We strive
                to comply with US laws where we maintain our operations but we make no representations that the practices
                described in this Privacy Policy are compliant with laws outside the US that apply to the collection,
                security, use and disclosure of personal information. </p> <a name="8"></a>
            <h3 class="text lh-copy f5"><a name="8">Links to other sites. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> We may permit others to link to this App or to post a link to their site on ours.
                We do not endorse these sites and are not responsible for other sites or their privacy practices. Please
                read their privacy policies before submitting information. </p> <a name="9"></a>
            <h3 class="text lh-copy f5"><a name="9">Changes to the Privacy Policy. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> As we grow and change we may amend this Privacy Policy. The Policy in effect at the
                time you use the App governs how we may use your information. If we make material changes we will post the
                revised Policy and the revised effective date on this App and will endeavor to provide notification to you.
                Please check back here from time to time to review any changes. <a name="10"></a></p>
            <h3 class="text lh-copy f5"><a name="10">Contacting us. </a><a href="#top">[top of page]</a></h3>
            <p class="text lh-copy f5"> This App is owned and operated by Frendli, Inc. You can contact us at 1150 Garden
                View Rd. #231561, Encinitas, CA 92024. </p>
        </div>
    </section>
  </Layout>
)

export default PrivacyPolicy
